require('../../node_modules/uikit/dist/js/uikit.min.js')
//import UIkit from 'uikit'
//UIkit.slider('.uk-slider', {autoplay:true});
$(document).on('click', ".mobile-menu-icon", function ( event ) {
    if($(this).find('input').is(':checked')) {
        $('.menu-container').addClass('open');
    } else {
        $('.menu-container').removeClass('open');
    }
} )

    var audio = new Audio('/fm-goud-bloematelier.mp3');
$(document).on('click', '.play-audio', function () {
    if($(this).hasClass('playing')) {
        audio.pause();
    } else {

        audio.play();

    }
    $(this).toggleClass('playing');
})
//UIkit.parallax(document.getElementById('home-about'), {bg: -200});
